import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Anti-Bribery and Anti-Corruption Policy">
    <Hero title="Anti-Bribery and Anti-Corruption Policy" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Heading type="title2">Policy Statement</Heading>
            <Text>
              Ember is committed to conducting business in an ethical and honest
              manner, and to implementing and enforcing systems that ensure
              bribery is prevented. We have zero-tolerance for bribery and
              corrupt activities. We are committed to acting professionally,
              fairly and with integrity in all business dealings and
              relationships, wherever we operate.
            </Text>
            <Text>
              We will constantly uphold all laws relating to anti-bribery and
              corruption in all the jurisdictions in which we operate. We are
              bound by the laws of the UK, including the Bribery Act 2010, in
              regards to our conduct both at home and abroad.
            </Text>
            <Heading type="title2">Who is covered by the policy?</Heading>
            <Text>
              This anti-bribery policy applies to all employees (whether
              temporary, fixed-term, or permanent), consultants, contractors,
              trainees, seconded staff, home workers, casual workers, agency
              staff, volunteers, interns, agents, sponsors, or any other person
              or persons associated with us (including third parties), or any of
              our subsidiaries or their employees, no matter where they are
              located (within or outside of the UK). The policy also applies to
              Officers, Trustees, Board and/or Committee members at any level.
            </Text>
            <Text>
              In the context of this policy, third-party refers to any
              individual or organisation our company meets and works with. It
              refers to actual and potential clients, customers, suppliers,
              distributors, business contacts, agents, advisers, and government
              and public bodies – this includes their advisors, representatives
              and officials, politicians and public parties.
            </Text>
            <Text>
              Any arrangements our company makes with a third party is subject
              to clear contractual terms, including specific provisions that
              require the third party to comply with minimum standards and
              procedures relating to anti-bribery and corruption.
            </Text>
            <Heading type="title2">Definition of bribery</Heading>
            <Text>
              Bribery refers to the act of offering, giving, promising, asking,
              agreeing, receiving, accepting, or soliciting something of value
              or of an advantage so to induce or influence an action or
              decision.
            </Text>
            <Text>
              A bribe refers to any inducement, reward, or object/item of value
              offered to another individual in order to gain commercial,
              contractual, regulatory, or personal advantage.
            </Text>

            <Text>
              Bribery is not limited to the act of offering a bribe. If an
              individual is on the receiving end of a bribe and they accept it,
              they are also breaking the law.
            </Text>

            <Text>
              Bribery is illegal. Employees must not engage in any form of
              bribery, whether it be directly, passively (as described above),
              or through a third party (such as an agent or distributor). They
              must not bribe a foreign public official anywhere in the world.
              They must not accept bribes in any degree and if they are
              uncertain about whether something is a bribe or a gift or act of
              hospitality, they must seek further advice from the compliance
              manager(s).
            </Text>

            <Heading type="title2">What is and is not acceptable</Heading>

            <Heading type="title3">Gifts and hospitality</Heading>

            <Text>
              Ember accepts normal and appropriate gestures of hospitality and
              goodwill (whether given to/received from third parties) so long as
              the giving or receiving of gifts meets the following requirements:
            </Text>

            <List>
              <ListItem>
                It is not made with the intention of influencing the party to
                whom it is being given, to obtain or reward the retention of a
                business or a business advantage, or as an explicit or implicit
                exchange for favours or benefits
              </ListItem>
              <ListItem>
                It is not made with the suggestion that a return favour is
                expected
              </ListItem>
              <ListItem>It is in compliance with local law</ListItem>
              <ListItem>
                It is given in the name of the company, not in an individual’s
                name
              </ListItem>
              <ListItem>
                It does not include cash or a cash equivalent (e.g. a voucher or
                gift certificate)
              </ListItem>
              <ListItem>
                It is appropriate for the circumstances (e.g. giving small gifts
                around Christmas or as a small thank you to a company for
                helping with a large project upon completion)
              </ListItem>
              <ListItem>
                It is of an appropriate type and value and given at an
                appropriate time, taking into account the reason for the gift
              </ListItem>
              <ListItem>It is given/received openly, not secretly</ListItem>
              <ListItem>
                It is not selectively given to a key, influential person,
                clearly with the intention of directly influencing them
              </ListItem>
              <ListItem>It is not above a certain excessive value</ListItem>
              <ListItem>
                It is not offered to, or accepted from, a government official or
                representative or politician or political party, without the
                prior approval of the company’s compliance manager(s)
              </ListItem>
              <ListItem>
                It is a promotional gift of low value (e.g. branded stationery)
              </ListItem>
            </List>

            <Text>
              Where it is inappropriate to decline the offer of a gift (i.e.
              when meeting with an individual of a certain religion/culture who
              may take offence), the gift may be accepted so long as it is
              declared to the compliance manager(s), who will assess the
              circumstances.
            </Text>

            <Text>
              We recognise that the practice of giving and receiving business
              gifts varies between countries, regions, cultures, and religions,
              so definitions of what is acceptable and not acceptable will
              inevitably differ for each.
            </Text>

            <Text>
              If in doubt, disclose the gift to the compliance manager(s) and
              they will provide advice.
            </Text>

            <Heading type="title3">Facilitation Payments and Kickbacks</Heading>

            <Text>
              We do not accept and will not make any form of facilitation
              payments of any nature. Facilitation payments are a form of
              bribery that involves expediting or facilitating the performance
              of a public official for a routine governmental action.
            </Text>

            <Text>
              We do not allow kickbacks to be made or accepted. Kickbacks are
              typically made in exchange for a business favour or advantage.
            </Text>

            <Text>
              In the rare case where avoiding a facilitation payment or kickback
              may put you or your family’s personal security at risk, the
              following steps must be taken:
            </Text>

            <List>
              <ListItem>Keep any amount to the minimum</ListItem>
              <ListItem>
                Ask for a receipt, detailing the amount and reason for the
                payment
              </ListItem>
              <ListItem>Create a record concerning the payment</ListItem>
              <ListItem>
                Report the incident to the compliance manager(s)
              </ListItem>
            </List>

            <Heading type="title3">Political Contributions</Heading>

            <Text>
              We will not make donations, whether in cash, kind, or by any other
              means, to support any political parties or candidates. We
              recognise this may be perceived as an attempt to gain an improper
              business advantage.
            </Text>

            <Heading type="title3">Charitable Contributions</Heading>

            <Text>
              We encourage charitable donations – whether services, knowledge,
              time, or direct financial contributions (cash or otherwise) – but
              will disclose all charitable contributions that we make.
            </Text>

            <Text>
              Employees must be careful to ensure that charitable contributions
              are not used to facilitate and conceal acts of bribery. If in
              doubt, ask the compliance manager(s).
            </Text>

            <Heading type="title3">Employee Responsibilities</Heading>

            <Text>
              As an employee of Ember, you must ensure that you read,
              understand, and comply with the information contained within this
              policy, and with any training or other anti-bribery and corruption
              information you are given.
            </Text>

            <Text>
              All employees and those under our control are equally responsible
              for the prevention, detection and reporting of bribery and other
              forms of corruption. They are required to avoid any activities
              that could lead to, or imply, a breach of this anti-bribery
              policy.
            </Text>

            <Text>
              If you have reason to believe or suspect that an instance of
              bribery or corruption has occurred or will occur in the future
              that breaches this policy, you must notify the compliance
              manager(s).
            </Text>

            <Text>
              If any employee breaches this policy, they will face disciplinary
              action and could face dismissal for gross misconduct. Ember has
              the right to terminate a contractual relationship with an employee
              if they breach this anti-bribery policy.
            </Text>

            <Heading type="title2">
              What happens if I need to raise a concern?
            </Heading>

            <Heading type="title3">How to raise a concern</Heading>

            <Text>
              If you suspect that there is an instance of bribery or corrupt
              activities occurring in relation to Ember, you are encouraged to
              raise your concerns at as early a stage as possible. If you’re
              uncertain about whether a certain action or behaviour can be
              considered bribery or corruption, you should speak to your manager
              or the compliance manager(s).
            </Text>

            <Heading type="title3">
              What to do if you are a victim of bribery or corruption
            </Heading>

            <Text>
              You must tell your compliance manager(s) as soon as possible if
              you are offered a bribe by anyone, if you are asked to make one,
              if you suspect that you may be bribed or asked to make a bribe in
              the near future, or if you have reason to believe that you are a
              victim of another corrupt activity.
            </Text>

            <Heading type="title3">Protection</Heading>

            <Text>
              If you refuse to accept or offer a bribe or you report a concern
              relating to potential act(s) of bribery or corruption, Ember
              understands that you may feel worried about potential
              repercussions. Ember will support anyone who raises concerns in
              good faith under this policy, even if investigation finds that
              they were mistaken.
            </Text>

            <Text>
              We will ensure that no one suffers any detrimental treatment as a
              result of refusing to accept or offer a bribe or other corrupt
              activities or because they reported a concern relating to
              potential act(s) of bribery or corruption.
            </Text>

            <Text>
              Detrimental treatment refers to dismissal, disciplinary action,
              treats, or unfavourable treatment in relation to the concern the
              individual raised.
            </Text>

            <Text>
              If you have reason to believe you’ve been subjected to unjust
              treatment as a result of a concern or refusal to accept a bribe,
              you should inform your manager or the compliance manager(s)
              immediately.
            </Text>

            <Heading type="title2">Record keeping</Heading>

            <Text>
              Ember will keep detailed and accurate financial records and will
              have appropriate internal controls in place to act as evidence for
              all payments made. We will declare and keep a written record of
              the amount and reason for hospitality or gifts accepted and given,
              and understand that gifts and acts of hospitality are subject to
              managerial review.
            </Text>

            <Text>
              As employees of Ember, you must declare and keep a written record
              of all hospitality or gifts given or received. You must also
              submit all expense claims relating to hospitality, gifts or
              payments to third parties in accordance with our expense policies,
              and record the reason for expenditures.
            </Text>

            <Text>
              All accounts, invoices and other records relating to dealings with
              third parties including suppliers and customers should be prepared
              with strict accuracy and completeness. Accounts must not be kept
              "off-book" to facilitate or conceal improper payments.
            </Text>

            <Heading type="title2">Monitoring and reviewing</Heading>

            <Text>
              Ember’s compliance manager(s) is responsible for monitoring the
              effectiveness of this policy and will review the implementation of
              it on a regular basis. They will assess its suitability, adequacy,
              and effectiveness.
            </Text>

            <Text>
              Internal control systems and procedures designed to prevent
              bribery and corruption are subject to regular audits to ensure
              that they are effective in practice.
            </Text>

            <Text>
              Any need for improvements will be applied as soon as possible.
              Employees are encouraged to offer their feedback on this policy if
              they have any suggestions for how it may be improved. Feedback of
              this nature should be addressed to the compliance manager(s).
            </Text>

            <Text>
              This policy does not form part of an employee’s contract of
              employment and Ember may amend it at any time so to improve its
              effectiveness at combatting bribery and corruption.
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
